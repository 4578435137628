<template>
	<BoxModal>
		<div>
			<p>After {{ data }}, your list was modified?</p>
		</div>
		<div>
			<button @click="cancelar">No</button>
			<span></span>
			<button @click="confirmar">Yes</button>
		</div>
	</BoxModal>
</template>

<script>
	import firebase from 'firebase';
	const BoxModal = () => import('@/components/modal/_ModalBox.vue');
	export default {
		name: 'ModalAtualizarLista',
		components: {
			BoxModal,
		},
		data() {
			return {
				uid: null,
				data: null,
			};
		},
		methods: {
			textLastUpdated() {
				const data = new Date();
				const options = {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				};
				return `${data.toLocaleDateString(
					'en-US',
					options
				)} ${data.toLocaleTimeString('en-US')}`;
			},
			cancelar() {
				this.$store.commit('SET_ADICIONAR_LOADING');
				this.$store.commit('user/SET_LAST_UPDATED', this.textLastUpdated());
				window.localStorage.setItem(
					`${this.uid}-updated`,
					this.textLastUpdated()
				);
				setTimeout(() => {
					this.$store.commit('SET_REMOVER_LOADING');
					this.$store.commit('SET_MODAL', '');
				}, 500);
			},
			confirmar() {
				this.$store.commit('user/SET_ATUALIZAR_LISTA', true);
				this.$store.commit('SET_MODAL', '');
			},
		},
		mounted() {
			this.uid = firebase.auth().currentUser.uid;
			this.data = window.localStorage.getItem(`${this.uid}-updated`);
		},
	};
</script>
